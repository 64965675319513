.loader-container {
  background-color: $bg-colour;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: map-get($zindex, loader);
  &.fade-out {
    animation: fadeOut 0.5s linear forwards;
  }
  .wrapper {
    background: url("../../img/camera.svg") no-repeat center;
    position: relative;
    width: 80px;
    height: 80px;
    .inner {
      position: absolute;
      right: 9px;
      bottom: 9px;
    }
  }
}

@keyframes fadeOut {
  0%,
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

$unit: 4px;
.lds-ring {
  display: inline-block;
  position: relative;
  width: $unit * 10;
  height: $unit * 10;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: $unit * 8;
    height: $unit * 8;
    margin: $unit;
    border: $unit solid $theme-orange;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $theme-orange transparent transparent transparent;
  }
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
