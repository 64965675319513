footer {
  background-color: $theme-dark;
  @include section-gap();
  margin: 1em auto 0;
  .grid {
    @include grid-layout(3);
  }
  a {
    color: rgba($theme-colour, 0.7);
    text-decoration: none;
    // text-transform: capitalize;
    margin-bottom: 0.5em;
  }
  p,
  li,
  blockquote {
    font-size: 14px;
    line-height: $baseline;
    color: rgba(white, 0.7);
    // opacity: 0.7;
    letter-spacing: 0.314px;
  }

  // blockquote {
  //   border-left: 2px dotted rgba(white, 0.1);
  //   margin: 0;
  //   padding: 0 1.5rem;
  //   quotes: "\201C""\201D""\2018""\2019";
  //   font-family: $Playfair;
  //   position: relative;
  //   &:before {
  //     @include pseudo(absolute, inline, open-quote);
  //     opacity: 0.38;
  //     left: 0;
  //     top: 1.5rem;
  //     font-size: 4em;
  //     line-height: 0.1em;
  //     // margin-right: 0.25em;
  //     vertical-align: -0.4em;
  //   }
  // }

  h3 {
    font-family: $Playfair;
    @include font-size(24px);
    &:after {
      @include deco-dash($baseline);
      // @include pseudo();
      // background-color: currentColor;
      // width: $baseline * 2;
      // height: 1px;
      margin: 0.625em 0;
      // opacity: 0.2;
    }
  }

  .copyright {


    // vertical divider
    &:before {
      @include deco-dash($baseline * 2);
      margin: $baseline auto;
    }
    // horizontal divider
    i.divider {
      margin: 0 0.75em;
      font-size: 0.75em;
      opacity: 0.2;
    }
  }
}
