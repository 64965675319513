button {
  background-color: $theme-colour;
  color: rgba(white, 0.87);
  padding: 0.75em 1.5em;
  border: 2px solid $theme-colour;
  font-weight: bold;
  transition: all 0.3s;
  text-transform: capitalize;
  &:hover {
    background-color: darken($theme-colour, 5%);
  }
  &:focus {
    outline: 0;
  }
  &.hollow {
    background-color: transparent;
    color: $theme-colour;
  }
  &.rounded {
    border-radius: $baseline * 3;
  }
  .group & {
    margin: 0.5em 0.25em;
    &:last-child {
      margin-right: 0;
    }
  }
}
