$breakpoints: (
  "mobile": 375px,
  "480": $screen-xs,
  "560": 560px,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lr,
  xl: 1280px,
  "1680": $screen-xl,
);
@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin section-gap($value: $baseline, $ratio: 2) {
  padding-top: $value;
  padding-bottom: $value;
  @include mq(lg) {
    padding-top: $value * $ratio;
    padding-bottom: $value * $ratio;
  }
}

@mixin grid-layout($gird, $row-gap: $baseline, $column-gap: $baseline) {
  display: grid;
  // place-items: center;
  row-gap: $row-gap;
  column-gap: $column-gap;
  @include mq(sm) {
    grid-template-columns: repeat($gird, 1fr);
  }
}

@mixin bg-color($color) {
  background-color: $color;
  transition: all 0.3s;
  &:hover {
    background-color: darken($color, 10%);
  }
}

@mixin font-size($value, $ratio: 1.14) {
  font-size: $value;
  @include mq(md) {
    font-size: $value * $ratio;
  }
}

@mixin push--auto {
  margin: {
    left: auto;
    right: auto;
  }
}

@mixin pseudo($pos: relative, $display: block, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin deco-dash(
  $width: 3em,
  $opacity: 0.2,
  $height: 1px,
  $colour: currentColor
) {
  @include pseudo();
  background-color: $colour;
  width: $width;
  height: $height;
  opacity: $opacity;
}
