/* hero */
@keyframes SlideIn {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 50%;
    opacity: 1;
  }
}
.outter-wrap {
  color: white;
  // background-color: rgba(red, 0.2);
  // background-image: url("../img/archiyuri.jpg");
  // background-attachment: fixed;
  padding: 0 2%;
  height: 100vh;
  max-height: $screen-lr;
  position: relative;
  /* gradient overlap */
  &:before {
    @include pseudo(absolute);
    background-color: rgba(black, 0.54);
    background: linear-gradient(
      0deg,
      rgba(black, 0.54) 75%,
      rgba(black, 0.7) 100%
    );
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: map-get($zindex, hero - 2);
  }
  .first-layer {
    width: 100%;
    max-width: 1280px;
    margin: -36px auto 0;
    position: relative;
    z-index: map-get($zindex, hero - 1);
  }
  .second-layer {
    // background-color: black;
    width: 50%;
    // opacity: 0;
    // animation-delay: 100ms;
    // animation: SlideIn 550ms cubic-bezier(0, 0.6, 0.75, 0.75);
    // animation-fill-mode: forwards;
    max-width: $screen-md;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 90vh;
    max-height: $screen-lr;
    object-fit: cover;
    object-position: center center;
    transition: opacity 500ms ease 0s;
    // opacity: 0.7;
    filter: brightness(87%);
  }
  .hero-heading {
    position: absolute;
    z-index: map-get($zindex, hero);
    top: 33%;
    left: $baseline;
    @include mq(sm) {
      top: 28%;
    }
    @include mq(lg) {
      left: 47%;
    }

    h1 {
      @include font-size(48px, 1.618);
      @include mq(xl) {
        // font-size: 88px;
        margin-top: 1em;
      }
      letter-spacing: -0.02em;
      line-height: 1.2;
      font-family: $Playfair;
      // opacity: 0.87;
      &:after {
        @include deco-dash(4rem, 1);
        margin-top: $baseline * 2;
      }
    }
  }
}
