.about-me {
  h2 {
    @include font-size(32px, 1.5);
    font-family: $Playfair;
    line-height: 1;
    margin: 0;
    &:after {
      @include deco-dash(1.25em, 0.5);
      margin: $baseline * 1.25 0;
    }
  }
}
