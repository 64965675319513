$theme-colour: #11ddff;
$theme-orange: #ff6633;
$theme-grey: #f2f2f2;
$theme-black: rgb(24, 24, 24);
$theme-dark: rgb(33, 33, 33);

$bg-colour: $theme-black;
$nav-colour: $theme-colour;
$baseline: 24px;

$Archivo: "Archivo", sans-serif;
$Playfair: "Playfair Display", serif;
$theme-font: $Archivo;

/* @media only screen */
$screen-xl: 1680px;
$screen-lr: 1024px;
$screen-md: 768px;
$screen-sm: 640px;
$screen-xs: 480px;

/* hamburger */
$bar-length: $baseline;
$bar-thick: 2px;
$bar-gap: 6px;
$bar-colour: $theme-grey;

/* off canvas */
$off-position: $bar-length + $baseline * 1.667; // hamburger width + spacing
$expanded-width: 240px;

$zindex: (
  overlay: 4000,
  header: 5000,
  nav: 9000,
  hero: 7000,
  modal: 8000,
  loader: 9900,
  title: 3000,
);
