#gallery {
  // @include mq(lg) {
  //   padding: 6rem 0;
  // }
  // max-width: 1280px;
  // margin: auto;
  // picture {
  //   /* gradient overlap */
  //   .lazyload-wrapper:before {
  //     @include pseudo(absolute);
  //     background: linear-gradient(
  //       0deg,
  //       rgba(black, 0.54) 0%,
  //       rgba(black, 0) 100%
  //     );
  //     bottom: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 50%;
  //     z-index: map-get($zindex, title - 1);
  //     pointer-events: none;
  //   }
  //   position: relative;
  //   background-color: black;
  //   overflow: hidden;
  //   img {
  //     position: absolute;
  //     transition: ease-in-out 500ms;
  //     opacity: 0.87;
  //   }
  //   &:hover {
  //     img {
  //       transform: scale(1.05);
  //       opacity: 1;
  //     }
  //   }
  //   a {
  //     display: block;
  //     height: 100%;
  //     width: 100%;
  //   }
  //   h2 {
  //     position: absolute;
  //     bottom: 1em;
  //     left: 1rem;
  //     margin: 0;
  //     color: rgb(255, 255, 255);
  //     z-index: map-get($zindex, title);
  //     letter-spacing: 0.25em;
  //     text-transform: uppercase;
  //     font-size: 0.625rem;
  //     font-weight: 500;
  //   }
  // }
  .gallery {
    display: grid;
    grid-gap: 24px;
    grid-template-rows: repeat(9, minmax(327px, auto));
    @include mq("560") {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, minmax($baseline * 12, auto));
    }
    @include mq(lg) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, minmax($baseline * 12, auto));
      grid-template-areas:
        "a b c"
        "d e e"
        "f e e"
        "g h i"
        "j k l";
      :nth-child(1) {
        grid-area: a;
      }
      :nth-child(2) {
        grid-area: b;
      }
      :nth-child(3) {
        grid-area: c;
      }
      :nth-child(4) {
        grid-area: d;
      }
      :nth-child(5) {
        grid-area: e;
      }
      :nth-child(6) {
        grid-area: f;
      }
      :nth-child(7) {
        grid-area: g;
      }
      :nth-child(8) {
        grid-area: h;
      }
      :nth-child(9) {
        grid-area: i;
      }
      :nth-child(10) {
        grid-area: j;
      }
      :nth-child(11) {
        grid-area: k;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
